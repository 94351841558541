import React from 'react';
import { Button } from 'semantic-ui-react'

function Home() {

  return (
    <>
        <div className='margin-container'>
            <div className='paper-background-box'>
                <div className="d-flex justify-content-center">
                    <div className="container-text col-lg-12">
                        <h5><b>Welcome to my website!</b></h5>
                        <p>It is a pleasure to count on you as one of my wonderful connections in the vast universe. Go around and visit the website, checkout the upcoming events and works, order and review published work, and take a detour to my social media pages for more personal and regular information. 
Thanks for stopping by; have a great and productive time here!</p>
                    </div>
                </div>
            </div>

            <div style={{
                backgroundColor: 'snow',
                paddingLeft: '2em',
                paddingRight: '2em',
                paddingTop: '0em',
                paddingBottom: '0em',
                fontSize: '1.25em',
                marginTop: '1em'
            }}>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="d-flex justify-content-center mt-lg-0 mt-md-4 mb-lg-0 mb-md-4">
                            <img src={require("../img/author-picture2.jpeg")} alt="book-front-cover" width={'44%'}/>
                        </div>
                        <div className='mt-4 mb-4' style={{fontSize: '0.85em'}}>
                            <q><b><i>Shaanchuong mu-Fohnpah is a Bantu-American author from Cameroon, presently working as an Engineer and finishing a second MSc. in Engineering. He designs his stories using notions in literature, engineering, and sciences as modules of a literaro-engineering project...</i></b></q>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <a href='/biography'><Button className='mb-4' size='medium' color='teal'>See more...</Button></a>
                        </div>
                    </div> 
                    <div className="col-lg-4 col-md-6">
                        <div className="d-flex justify-content-center mt-lg-0 mt-md-4 mb-lg-0 mb-md-4">
                            <img src={require("../img/book-front.jpeg")} alt="book-front-cover" width={'87%'}/>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className='mt-4 mb-4'>
                            <p style={{fontSize: '0.80em'}}><b>ISBN: </b>9789956552344</p>
                            <p style={{fontSize: '0.80em'}}><b>Pages: </b>254</p>
                            <p style={{fontSize: '0.80em'}}><b>Dimensions: </b>216 x 216mm</p>
                            <p style={{fontSize: '0.80em'}}><b>Published: </b>2022</p>
                            <p style={{fontSize: '0.80em'}}><b>Publisher: </b>Langaa RPCIG</p>
                            <p style={{fontSize: '0.80em'}}><b>Format: </b>Paperback</p>
                            <p style={{fontSize: '0.95em'}} className='mt-3'><b>Now available on all Amazon Regional Hubs</b></p>
                            <Button href='https://www.amazon.com/Unbreakable-Chains-Shaanchuong-mu-Fohnpah/dp/9956552348/' className='mb-3' size='tiny' color='teal' target="_blank">USA</Button>
                            <Button href='https://www.amazon.ca/Unbreakable-Chains-Shaanchuong-Mu-Fohnpah/dp/9956552348/' className='mb-3' size='tiny' color='teal' target="_blank">Canada</Button>
                            <Button href='https://www.amazon.co.uk/Unbreakable-Chains-Shaanchuong-Mu-Fohnpah/dp/9956552348/' className='mb-3' size='tiny' color='teal' target="_blank">UK</Button>
                            <Button href='https://www.amazon.fr/Unbreakable-Chains-Shaanchuong-Mu-Fohnpah/dp/9956552348/' className='mb-3' size='tiny' color='teal' target="_blank">France</Button>
                            <Button href='https://www.amazon.de/-/en/Shaanchuong-Mu-Fohnpah/dp/9956552348/' className='mb-3' size='tiny' color='teal' target="_blank">Germanic Europe</Button>
                            <p style={{fontSize: '0.95em'}}><b>Now available on</b></p>
                            <Button href='https://www.africanbookscollective.com/books/unbreakable-chains' className='mb-4' size='small' color='teal' target="_blank">African Books Collective</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='paper-background-box' style={{marginTop: '1em'}}>
                <div className="d-flex justify-content-center">
                    <div className="container-text col-lg-10">
                    The idea of absolute freedom warrants the freedom to define freedom; so long as one's freedom is predefined, they can never claim to be free. Yet, the previous sentence already confines absolute freedom to a certain meaning. Freedom is an ideal, we are all captives going from one prison cell to another. Mercy Lewis, a descendant of enslaved free men and free women is nothing else but a slave to her African heritage, her past, and all the stereotypes associated with her personality. We all are Mercys to a certain extent.
                    </div>
                </div>
                
            </div>
        </div>
    </>
  );
}

export default Home;
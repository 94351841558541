import React from 'react';
import { Button } from 'semantic-ui-react'

function News() {

  return (
    <>
        <div className='margin-container'>
            <div style={{
                background: 'snow',
                paddingLeft: '2em',
                paddingRight: '2em',
                paddingTop: '0em',
                paddingBottom: '0em',
                fontSize: '1.25em'
            }}>
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <div className='mt-4 mb-4'>
                        <h4 className='mb-4'>News & Collaboration</h4>
                        <ul>
                            <li style={{fontSize: '0.9em', marginTop: '2em', marginBottom: '1em'}}>Collaborated on an anthology of 74 poems entitled <b>WOLE SOYINKA: THE HERALD AT 90</b>, made in honor of the 90<sup>th</sup> birthday of Wole Soyinka. This celebration of the first African Literature Nobel Prize Winner was sponsored by King Sidi Mohamed VI and the Kingdom of Morocco.</li>
                            <div className="d-flex justify-content-center mt-lg-0 mt-md-4 mb-lg-0 mb-md-4">
                                <img src={require("../img/wole-soyinka.jpg")} alt="wole-soyinka-book" width={'100%'}/>
                            </div>
                            <div className="d-flex justify-content-center mt-lg-0 mt-md-4 mb-lg-0 mb-md-4">
                                <div className='mt-4'>
                                    <Button href='/contact?reason=theherald' className='mb-4' size='small' color='teal'>REGULAR COPY - $20 + shipping</Button>
                                    <Button href='/contact?reason=theheraldsigned' className='mb-4' size='small' color='teal'>EDITOR'S SIGNED COPY - $25 + shipping</Button>
                                </div>
                            </div>
                            <li style={{fontSize: '0.9em', marginTop: '3em'}}>February 25th @ 13:00 EST, book discussion with Tuskegee University Interdisciplinary Seminar Series. <br/><b><i>Live streams links:</i></b> <a href='https://www.youtube.com/live/XoGXXbmIDK8?feature=share' target="_blank" rel="noopener noreferrer">youtube-live</a>, <a href='https://tuskegee.zoom.us/j/95077588185?pwd=eFRTamZya3V2RGNrUVVMbXgwNXdRZz09' target="_blank" rel="noopener noreferrer">zoom-live</a> (Meeting ID: 950 7758 8185 Passcode: 436729)</li>
                            <li style={{fontSize: '0.9em', marginTop: '3em'}}>December 10th, 2022 @ 11:00 CST: Digital Website Launch Party on my Facebook page Shaanchuong Mu-Fohnpah</li>
                            <li style={{fontSize: '0.9em', marginTop: '3em'}}>November 10th, 2022 @ 11:00 CST: Digital Book Launch Party for Unbreakable Chains on my Facebook page Shaanchuong Mu-Fohnpah</li>
                            <li style={{fontSize: '0.9em', marginTop: '3em', marginBottom: '3em'}}>Watch the Digital Book Launch Party for Unbreakable Chains <a href='https://www.facebook.com/1169037143/videos/1420447245025539/' target="_blank" rel="noopener noreferrer">here</a></li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </div>
    </>
  );
}

export default News;